import React, { useState } from 'react'
import Layout from '../components/Layout'

import BranchTabs from '../components/BranchTabs'
import { BRANCH_LIST } from '../urls'
import { useLoad } from '../hooks/request'
import Table from '../components/common/Table'
import BranchReportItem from '../components/BranchReportItem'
import { PermissionDenied } from '../components/PermissionDenied'
import { useUser } from '../hooks/user'
import { roleBranch } from '../utils/role'
import useTrans from '../hooks/trans'

export default function BranchReport() {
    const [user] = useUser()
    const [page, setPage] = useState(1)
    const branch = useLoad({ url: BRANCH_LIST, params: { page } }, [page])
    const t = useTrans()

    if (!roleBranch.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <BranchTabs />

            <h1 className="title is-3 has-text-weight-normal my-5">{t('listBranch')}</h1>

            <Table
                loading={branch.loading}
                totalCount={branch.response ? branch.response.count : 0}
                items={branch.response ? branch.response.results : []}
                activePage={page}
                onPageChange={setPage}
                columns={{ name: t('name'), total: t('total') }}
                renderItem={(item) => (<BranchReportItem key={item.id} item={item} />)} />
        </Layout>
    )
}
