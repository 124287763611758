import { Form, Formik } from 'formik'
import React, { Fragment } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import Button from './common/Button'
import { domain } from '../utils/request'
import { useMessage } from '../hooks/message'
import BranchReportFormItem from './BranchReportFormItem'
import useTrans from '../hooks/trans'
import { useModal } from '../hooks/modal'
import BranchRejectModal from './BranchRejectModal'
import { roleMain } from '../utils/role'
import { useUser } from '../hooks/user'
import { usePutRequest } from '../hooks/request'
import { BRANCH_REPORT_CHANGE_DETAIL } from '../urls'

export default function BranchReportForm({
    onSubmit, report, branchReportDetail, initialValues = {}, channels, loading,
}) {
    const [showMessage] = useMessage()
    const t = useTrans()
    const [user] = useUser()
    const reportUpdate = usePutRequest({ url: BRANCH_REPORT_CHANGE_DETAIL.replace('{id}', report.id) })

    const [showRejectModal, hideRejectModal] = useModal(
        <BranchRejectModal
            item={report}
            onSuccess={() => {
                branchReportDetail.request()
                hideRejectModal()
            }}
            onCancel={() => {
                hideRejectModal()
            }} />,
    )

    async function download() {
        if (!branchReportDetail.response || !branchReportDetail.response.xlsx) {
            showMessage(t('fillFieldThenSave'), 'is-danger')
            return
        }

        const a = document.createElement('a')
        a.href = [`${domain}/${branchReportDetail.response.xlsx}`]
        a.setAttribute('download', 'response')
        a.click()
    }

    async function onAccepted() {
        const { error } = await reportUpdate.request({ data: {
            ...report,
            status: 'accepted',
            branch: report.branch ? report.branch.id : null,
            responsible: report.responsible ? report.responsible.id : null,
            acceptedBy: report.acceptedBy ? report.acceptedBy.id : null,
            rejectedBy: report.rejectedBy ? report.rejectedBy.id : null,
        } })

        if (error) {
            showMessage(error.data, 'is-danger')
            return
        }

        branchReportDetail.request()
    }

    return (
        <Formik onSubmit={onSubmit} initialValues={initialValues}>
            {({ errors, values }) => (
                <Form className="pb-6">
                    <table className={cn(css(styles.table), css(styles.element), 'has-text-centered')}>
                        <thead>
                            <tr>
                                <th className={css(styles.border)}>{t('channel')}</th>
                                <th className={css(styles.border)}>{t('forMorning')}</th>
                                <th className={css(styles.border)}>{t('forEvening')}</th>
                                <th className={css(styles.border)}>{t('forAverage')}</th>
                            </tr>

                            {channels.response ? channels.response.results.map((item, index) => (
                                <BranchReportFormItem item={item} index={index} errors={errors} values={values} />
                            )) : null}
                        </thead>
                    </table><br />

                    <div className="is-flex is-justify-content-flex-end">
                        {branchReportDetail.response && roleMain.includes(user.role) ? (
                            <Fragment>
                                <Button
                                    text={t('accept')}
                                    onClick={onAccepted}
                                    loading={branchReportDetail.loading || reportUpdate.loading}
                                    className="is-success"
                                    icon="ion-md-checkmark" />

                                <Button
                                    onClick={showRejectModal}
                                    text={t('reject')}
                                    className="is-danger ml-2"
                                    icon="ion-md-close" />
                            </Fragment>
                        ) : null}

                        <Button
                            className="is-info ml-2"
                            icon="ion-md-download"
                            text={t('download')}
                            loading={branchReportDetail.loading}
                            onClick={download} />

                        <Button
                            icon="ion-md-checkmark"
                            loading={loading}
                            className="is-success ml-2"
                            text={t('save')}
                            type="submit" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const styles = StyleSheet.create({
    border: {
        border: '1px solid black',
        width: 1130,
    },
    table: {
        border: '1px solid black',
        width: '100%',
    },
    container: {
        overflow: 'auto',
        padding: '0 100px 15px',
    },
    element: {
        minWidth: 100,
        scrollSnapStop: 'normal',
    },
    input: {
        all: 'unset',
        padding: '7px 0',
        width: '100%',
    },
})
