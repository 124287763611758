import React from 'react'
import { CHANNEL_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import ChannelForm from './ChannelForm'
import ServerError from './common/ServerError'
import useTrans from '../hooks/trans'

export default function ChannelUpdate({ onCancel, onUpdate, item }) {
    const url = CHANNEL_DETAIL.replace('{id}', item.id)
    const channelUpdate = usePutRequest({ url })
    const t = useTrans()

    async function onSubmit(data, actions) {
        const { success } = await channelUpdate.request({ data })

        if (success) {
            actions.resetForm()
            onUpdate()
        }
    }

    return (
        <div>
            <b>{t('editing')}</b><br /><br />
            <ServerError error={channelUpdate.error} />
            <ChannelForm
                initialValues={item}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={channelUpdate.loading} />
        </div>
    )
}
