import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { isEmpty, range } from 'lodash'
import { useFormikContext } from 'formik'
import { required } from '../utils/validators'
import TableInput from './TableInput'

export default function DistrictReportFormItem({ item, index, activeIrrigation, period, disabled }) {
    const { errors, handleChange, setFieldValue } = useFormikContext()

    const calculateCapacity = (e) => {
        handleChange(e)
        setFieldValue(`items.${index}.capacity`, (e.target.value * 40).toFixed(0))
    }

    return (
        <tr key={item.id}>
            <td className={cn(css(styles.border), 'pt-1')}>{item.name}</td>

            <td className={cn(css(styles.border), { 'has-background-white-ter': disabled })}>
                <TableInput
                    name={`items.${index}.limit`}
                    className={cn(css(styles.input), 'has-text-centered')}
                    min="0"
                    disabled={disabled}
                    step="0.01"
                    isValid={!isEmpty(errors) && errors.items && errors.items[index] ? (
                        errors.items[index].limit
                    ) : null}
                    type="number"
                    validate={required} />
            </td>

            <td className={cn(css(styles.border), 'has-background-white-ter')}>
                <TableInput
                    name={`items.${index}.yesterdayWater`}
                    className={cn(css(styles.input), 'has-text-centered')}
                    disabled
                    type="number" />
            </td>

            <td className={cn(css(styles.border), { 'has-background-white-ter': disabled })}>
                <TableInput
                    name={`items.${index}.amountWater`}
                    className={cn(css(styles.input), 'has-text-centered')}
                    min="0"
                    disabled={disabled}
                    isValid={!isEmpty(errors) && errors.items && errors.items[index] ? (
                        errors.items[index].amountWater
                    ) : null}
                    onChange={calculateCapacity}
                    validate={required}
                    step="0.01"
                    type="number" />
            </td>

            <td className={cn(css(styles.border), { 'has-background-white-ter': disabled })}>
                <TableInput
                    name={`items.${index}.capacity`}
                    className={cn(css(styles.input), 'has-text-centered')}
                    min="0"
                    disabled={disabled}
                    isValid={!isEmpty(errors) && errors.items && errors.items[index] ? (
                        errors.items[index].capacity
                    ) : null}
                    validate={required}
                    step="0.01"
                    type="number" />
            </td>

            <td className={cn(css(styles.border), { 'has-background-white-ter': disabled })}>
                <TableInput
                    name={`items.${index}.totalAreaDay`}
                    className={cn(css(styles.input), 'has-text-centered')}
                    isValid={!isEmpty(errors) && errors.items && errors.items[index] ? (
                        errors.items[index].totalAreaDay
                    ) : null}
                    min="0"
                    disabled={disabled}
                    validate={required}
                    step="0.01"
                    type="number" />
            </td>

            {activeIrrigation.map((elem) => (
                range(period[elem.key]).map((i) => (
                    <td key={i} className={cn(css(styles.border), { 'has-background-white-ter': disabled })}>
                        <TableInput
                            disabled={disabled}
                            name={`items.${index}.${elem.key}.${i}.day`}
                            className={cn(css(styles.input), 'has-text-centered')}
                            min="0"
                            type="number" />
                    </td>
                ))
            ))}

            <td className={cn(css(styles.border), { 'has-background-white-ter': disabled })}>
                <TableInput
                    disabled={disabled}
                    name={`items.${index}.watermanCount`}
                    className={cn(css(styles.input), 'has-text-centered')}
                    min="0"
                    isValid={!isEmpty(errors) && errors.items && errors.items[index] ? (
                        errors.items[index].watermanCount
                    ) : null}
                    validate={required}
                    type="number" />
            </td>
        </tr>
    )
}

const styles = StyleSheet.create({
    border: {
        border: '1px solid black',
        minWidth: 110,
    },
    input: {
        all: 'unset',
        padding: '7px 0',
        width: '100%',
    },
})
