import React from 'react'
import { usePostRequest } from '../hooks/request'
import { USER_INVITATION } from '../urls'
import ResponsibleForm from './ResponsibleForm'

export default function ResponsibleCreate({ onCancel, onSuccess, users }) {
    const userCreate = usePostRequest({ url: USER_INVITATION })

    async function onSubmit(data) {
        await userCreate.request({ data: {
            ...data,
            username: data.email,
        } })

        onSuccess()
    }

    return (
        <div>
            <ResponsibleForm
                users={users}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={userCreate.loading} />
        </div>
    )
}
