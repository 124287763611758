export const role = {
    main: 'Админ',
    branch: 'Насосные станцы',
    district: 'Район',
    districtSector: 'Сектор района',
}

export const roleMain = ['main']
export const roleBranch = ['main', 'branch']
export const roleDistrictSector = ['main', 'districtSector']
export const roleDistrict = ['main', 'district']
export const roleDistrictWithSector = ['main', 'district', 'districtSector']

export function position(name) {
    return role[name]
}
