import React from 'react'
import { BRANCH_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import BranchForm from './BranchForm'
import ServerError from './common/ServerError'
import useTrans from '../hooks/trans'

export default function BranchUpdate({ onCancel, onUpdate, item, responsibles }) {
    const url = BRANCH_DETAIL.replace('{id}', item.id)
    const branchUpdate = usePutRequest({ url })
    const t = useTrans()

    async function onSubmit(data, actions) {
        const { success } = await branchUpdate.request({ data })

        if (success) {
            actions.resetForm()
            onUpdate()
        }
    }

    return (
        <div>
            <b>{t('editing')}</b><br /><br />
            <ServerError error={branchUpdate.error} />
            <BranchForm
                responsibles={responsibles}
                initialValues={item}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={branchUpdate.loading} />
        </div>
    )
}
