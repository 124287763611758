import React, { createContext, useState } from 'react'
import Modal from './Modal'
import Message from './Message'
// eslint-disable-next-line import/no-cycle
import ParseMessage from '../ParseMessage'
import { roleMain } from '../../utils/role'

export const Context = createContext()

export default function BaseContextWrapper({ children }) {
    const [messages, setMessages] = useState([])
    const [modalComponent, setModalComponent] = useState({})
    const [user, setUser] = useState(localStorage.user ? JSON.parse(localStorage.user) : null)
    const [reports, setReports] = useState([])

    function closeMessage(id) {
        setMessages(messages.filter((i) => i.id !== id))
    }

    return (
        <Context.Provider value={{ messages, setMessages, setModalComponent, user, setUser, reports }}>
            {children}

            {user && roleMain.includes(user.role) ? (
                <ParseMessage messages={messages} setMessages={setMessages} reports={reports} setReports={setReports} />
            ) : null}

            {messages.map((item, index) => (
                <Message
                    key={item.id}
                    item={item}
                    index={index}
                    closeMessage={closeMessage} />
            ))}

            {modalComponent && modalComponent.content ? (
                <Modal
                    isActive
                    onClose={() => {
                        if (typeof modalComponent.onClose === 'function') {
                            modalComponent.onClose()
                        }
                        setModalComponent(null)
                    }}>
                    {modalComponent.content}
                </Modal>
            ) : null}
        </Context.Provider>
    )
}
