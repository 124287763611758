import React, { Fragment } from 'react'
import { useModal } from '../hooks/modal'
import DocsTopicUpdate from './DocsTopicUpdate'

export default function DocsTopicItem({ item, onUpdate }) {
    const [showUpdateModal, hideUpdateModal] = useModal(
        <DocsTopicUpdate
            item={item}
            onUpdate={() => {
                onUpdate()
                hideUpdateModal()
            }} onCancel={() => {
                hideUpdateModal()
            }} />,
    )

    return (
        <Fragment>
            <tr>
                <td>
                    {item.title}
                </td>

                <td>
                    {item.shortTitle}
                </td>

                <td>
                    {item.slug}
                </td>

                <td>
                    <div dangerouslySetInnerHTML={{ __html: item.body }} />
                </td>

                <td>
                    {item.category.name}
                </td>

                <td className="is-flex is-justify-content-flex-end">
                    <i onClick={showUpdateModal} className="icon pointer ion-md-create" />
                </td>
            </tr>
        </Fragment>
    )
}
