import React, { Fragment } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useModal } from '../hooks/modal'
import ChannelUpdate from './ChannelUpdate'
import { useUser } from '../hooks/user'

export default function ChannelItem({ item, onUpdate }) {
    const [user] = useUser()
    const [showUpdateModal, hideUpdateModal] = useModal(
        <ChannelUpdate item={item} onUpdate={() => {
            onUpdate()
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    return (
        <Fragment>
            <tr>
                <td>
                    {item.name}
                </td>

                {user.role === 'main' ? (
                    <td className="is-flex is-justify-content-flex-end">
                        <i
                            onClick={showUpdateModal}
                            className={cn('icon pointer ion-md-create', css(styles.channelItem))} />
                    </td>
                ) : null }
            </tr>
        </Fragment>
    )
}

const styles = StyleSheet.create({
    channelItem: {
        cursor: 'pointer',
        ':hover': {
            color: '#3273dc',
        },
    },

})
