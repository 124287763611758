import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../components/Layout'
import { useLoad } from '../hooks/request'
import { BRANCH_DETAIL, CHANNEL_LIST } from '../urls'
import { useModal } from '../hooks/modal'
import ChannelCreate from '../components/ChannelCreate'
import ChannelItem from '../components/ChannelItem'
import Table from '../components/common/Table'
import BranchTabs from '../components/BranchTabs'
import Button from '../components/common/Button'
import { useUser } from '../hooks/user'
import { PermissionDenied } from '../components/PermissionDenied'
import Loader from '../components/common/Loader'
import { roleMain } from '../utils/role'
import useTrans from '../hooks/trans'

export default function Channel() {
    const params = useParams()
    const [user] = useUser()
    const [page, setPage] = useState(1)
    const channel = useLoad({ url: CHANNEL_LIST, params: { branch: params.id, page } }, [page])
    const branch = useLoad({ url: BRANCH_DETAIL.replace('{id}', params.id) })
    const t = useTrans()
    const branchName = branch.response ? branch.response.name : []

    const [showCreateModal, hideCreateModal] = useModal(
        <ChannelCreate
            branch={params.id}
            onSuccess={() => {
                hideCreateModal()
                channel.request()
            }}
            onCancel={() => hideCreateModal()} />,
    )

    if (!roleMain.includes(user.role)) {
        return <PermissionDenied />
    }

    if (branch.loading || channel.loading) {
        return (
            <Layout>
                <BranchTabs />
                <Loader large center />
            </Layout>
        )
    }

    return (
        <Layout>
            <BranchTabs showCreateModal={showCreateModal} />

            <div className="columns m-0">
                <div className="column">
                    <h3 className="title is-3 has-text-weight-normal">{t('listChannels')} "{branchName}"</h3>
                </div>

                {user.role === 'main' ? (
                    <div className="column">
                        <Button
                            onClick={showCreateModal}
                            text={t('add')}
                            icon="ion-md-add"
                            className="is-pulled-right is-link is-outlined mb-2" />
                    </div>
                ) : null }
            </div>

            <Table
                loading={channel.loading}
                activePage={page}
                onPageChange={setPage}
                totalCount={channel.response ? channel.response.count : 0}
                items={channel.response ? channel.response.results : []}
                columns={user.role === 'main' ? { name: t('name'), actions: '' } : { name: t('name') }}
                renderItem={(item) => (
                    <ChannelItem
                        key={item.id}
                        item={item}
                        onUpdate={channel.request} />
                )} />
        </Layout>
    )
}
