import React from 'react'
import RejectForm from './RejectForm'
import { usePutRequest } from '../hooks/request'
import { useMessage } from '../hooks/message'
import { BRANCH_REPORT_CHANGE_DETAIL } from '../urls'

export default function BranchRejectModal({ item, loading, onCancel, onSuccess }) {
    const reportUpdate = usePutRequest({ url: BRANCH_REPORT_CHANGE_DETAIL.replace('{id}', item.id) })
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const { error } = await reportUpdate.request({ data: { ...data, status: 'rejected' } })

        if (error) {
            showMessage(error.data, 'is-danger')
            return
        }

        onSuccess()
    }

    return (
        <RejectForm
            onCancel={onCancel}
            onSubmit={onSubmit}
            loading={loading}
            initialValues={{
                ...item,
                branch: item.branch ? item.branch.id : null,
                responsible: item.responsible ? item.responsible.id : null,
                rejectedBy: item.rejectedBy ? item.rejectedBy.id : null,
                acceptedBy: item.acceptedBy ? item.acceptedBy.id : null,
            }} />
    )
}
