import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Form, Formik } from 'formik'
import React from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import Button from '../components/common/Button'
import Input from '../components/common/Input'
import ServerError from '../components/common/ServerError'
import { usePostRequest } from '../hooks/request'
import { SIGNIN } from '../urls'
import { email, required, validator } from '../utils/validators'
import Password from '../components/Password'
import { signin, isAuthenticated } from '../utils/auth'
import { useUser } from '../hooks/user'
import LayoutAuth from '../components/LayoutAuth'
import useTrans from '../hooks/trans'

export default function Login() {
    const history = useHistory()
    const signIn = usePostRequest({ url: SIGNIN, headers: {} })
    const [user, setUser] = useUser()
    const t = useTrans()

    if (isAuthenticated()) {
        return (
            // eslint-disable-next-line no-nested-ternary
            <Redirect to={user && user.role === 'branch' ? (
                `/branch/${user.branch.id}/district`
            // eslint-disable-next-line no-nested-ternary
            ) : (user.role === 'districtSector') ? (
                '/district/archives'
            ) : (user.role === 'district') ? (
                `district/${user.district.id}/reports`
            ) : '/dashboard'} />
        )
    }

    async function onSubmit(data) {
        const { response, success } = await signIn.request({ data })

        if (success) {
            setUser(response.user)
            signin(response, history)
        }
    }

    return (
        <LayoutAuth>
            <h2 className={cn('is-size-4 has-text-weight-bold', css(styles.hasTextPrimary))}>
                {t('logInSystem')}
            </h2>
            <br />

            <Formik onSubmit={onSubmit} initialValues={{ email: '', password: '' }}>
                <Form>
                    <ServerError error={signIn.error} />
                    <Input
                        name="email"
                        icon="ion-md-mail"
                        validate={validator(required, email)}
                        placeholder="Email" />

                    <Password
                        name="password"
                        icon="ion-md-key"
                        validate={required}
                        placeholder="Password" />

                    <div className="field">
                        <div className="control">
                            <Button
                                loading={signIn.loading}
                                className="button is-link is-fullwidth is-medium"
                                text={t('auth')}
                                type="submit" />
                        </div>
                    </div>
                </Form>
            </Formik>
        </LayoutAuth>
    )
}

const styles = StyleSheet.create({
    container: {
        padding: '1.25rem',
        marginTop: 150,
    },
    img: {
        width: '10rem',
    },
    roundedButton: {
        borderRadius: '50%',
    },
    input: {
        color: '#4a4a4a',
    },
    onlyMobile: {
        textAlign: 'center',
        '@media (min-width: 769px)': {
            display: 'none',
        },
    },
    forgotPassword: {
        color: '#999',
        fontSize: '.9rem',
        ':hover': {
            color: '#0062ff',
        },
    },
})
