import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import useTrans from '../hooks/trans'

export default function SeasonTabs() {
    const { path } = useRouteMatch()
    const { location } = useHistory()
    const t = useTrans()

    return (
        <div className="tabs mb-2">
            <ul className={cn('is-flex is-justify-content-space-between', css(styles.tabs))}>
                <div className="is-flex">
                    <li className={cn({ 'is-active': location.pathname === '/season' })}>
                        <NavLink to="/season" className={css(styles.itemTabs)}>
                            {t('seasons')}
                        </NavLink>
                    </li>

                    <li className={cn({ 'is-active': path.startsWith('/season/period') })}>
                        <NavLink to="/season/period" className={css(styles.itemTabs)}>
                            {t('controlIrrigation')}
                        </NavLink>
                    </li>
                </div>
            </ul>
        </div>
    )
}

const styles = StyleSheet.create({
    itemTabs: {
        borderStyle: 'none',
    },
    tabs: {
        height: '50px',
    },
})
