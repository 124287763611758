import React from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../components/Layout'
import { useLoad, usePutRequest } from '../hooks/request'
import { PERIOD, PLAN_DETAIL, TERRITORY_LIST } from '../urls'
import DistrictTabs from '../components/DistrictTabs'
import DistrictPlanForm from '../components/DistrictPlanForm'
import Loader from '../components/common/Loader'
import { PermissionDenied } from '../components/PermissionDenied'
import { useMessage } from '../hooks/message'
import { roleMain } from '../utils/role'

export default function DistrictPlans() {
    const { districtId } = useParams()
    const territory = useLoad({ url: TERRITORY_LIST, params: { district: districtId, isArchive: false, size: 100 } })
    const planUpdate = usePutRequest({ url: PLAN_DETAIL.replace('{districtId}', districtId) })
    const planDetail = useLoad({ url: PLAN_DETAIL.replace('{districtId}', districtId) })
    const period = useLoad({ url: PERIOD })
    const user = JSON.parse(localStorage.getItem('user'))
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const value = territory.response ? (
            territory.response.results.map((item) => ({ ...data[item.id], territory: item.id }))
        ) : []

        const { success } = await planUpdate.request({ data: {
            irrigationManagement: user.irrigationManagement,
            district: districtId,
            items: value,
        } })

        if (success) {
            showMessage('Успешно сохранена!', 'is-success')
        }
    }

    const updatePlanItem = planDetail.response ? planDetail.response.items : []
    const initialValues = {}

    if (territory.response) {
        // eslint-disable-next-line array-callback-return
        territory.response.results.map((item) => {
            initialValues[item.id] = {
                grain: 0,
                cotton: 0,
                repeatIrrigation: 0,
                garden: 0,
                saltWash: 0,
                preparation: 0,
                cottonWash: 0,
                saltWashPlow: 0,
                grainPreparation: 0,
                potatoPreparation: 0,
                ...updatePlanItem.filter((plan) => (plan.territory === item.id))[0],
            }
        })
    }

    if (territory.loading || planDetail.loading || period.loading) {
        return <Layout><DistrictTabs /><Loader large center /></Layout>
    }

    if (!roleMain.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <DistrictTabs />
            <br />

            <div>
                {territory.response && territory.response.results && !planDetail.loading ? (
                    <DistrictPlanForm
                        loading={planUpdate.loading}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        period={period.response}
                        territory={territory} />
                ) : <Loader large center />}
            </div>
        </Layout>
    )
}
