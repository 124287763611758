import React from 'react'
import { usePostRequest } from '../hooks/request'
import { DISTRICT_LIST } from '../urls'
import DistrictForm from './DistrictForm'
import useTrans from '../hooks/trans'

export default function DistrictCreate({ hideModal, onSuccess, sector, branch }) {
    const districtCreate = usePostRequest({ url: DISTRICT_LIST })
    const t = useTrans()

    async function onSubmit(data, actions) {
        const { success } = await districtCreate.request({
            data: { ...data, channels: data.channels.map((item) => item.value) },
        })

        if (success) {
            actions.resetForm()
            onSuccess()
            hideModal()
        }
    }

    return (
        <div>
            <h3 className="title is-5">{t('addNewRegion')}</h3>

            <DistrictForm
                sector={sector}
                onCancel={hideModal}
                branch={branch}
                loading={districtCreate.loading}
                onSubmit={onSubmit} />
        </div>

    )
}
