import React, { Fragment, useContext, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import cn from 'classnames'
import { signOut } from '../utils/auth'
import { useDeleteRequest } from '../hooks/request'
import { SIGNOUT } from '../urls'
import { useUser } from '../hooks/user'
import { roleBranch, roleDistrictWithSector } from '../utils/role'
import { LanguageContext } from '../contexts/LanguageContext'
import useTrans from '../hooks/trans'
import uzLogo from '../static/uz.png'
import ruLogo from '../static/ru.png'
import { ThemeContext } from '../contexts/ThemeContext'

export default function Navbar() {
    const [active, setActive] = useState(false)
    const history = useHistory()
    const [user, setUser] = useUser()
    const signout = useDeleteRequest({ url: SIGNOUT })
    const { path } = useRouteMatch()
    const { lang, setLang } = useContext(LanguageContext)
    const { theme } = useContext(ThemeContext)
    const t = useTrans()

    function logOut() {
        signout.request({}, true)
        signOut(history)
        setUser({})
    }

    let territoryLink = '/district-sector/reports'
    if (user.role === 'districtSector') {
        territoryLink = '/district/archives'
    }
    if (user.role === 'district') {
        territoryLink = `/district/${user.district.id}/reports`
    }

    return (
        <div className={cn(`has-background-${theme.slice(3)} mb-2`, css(styles.navbar))}>
            <nav className={`navbar container ${theme}`} role="navigation" aria-label="main navigation">
                {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus,jsx-a11y/anchor-is-valid */}
                <a
                    role="button"
                    className="navbar-burger"
                    aria-label="menu"
                    aria-expanded="false"
                    onClick={() => setActive(!active)}
                    data-target="test">
                    <span aria-hidden="true" />
                    <span aria-hidden="true" />
                    <span aria-hidden="true" />
                </a>

                <div id="test" className={cn('navbar-menu', { 'is-active': active })}>
                    <div className="navbar-start">
                        {user.role === 'main' ? (
                            <NavLink
                                to="/dashboard"
                                className={cn(
                                    'navbar-item',
                                    { [css(styles.borderIsActive)]: path.startsWith('/dashboard') },
                                )}>
                                <i className="icon ion-ios-pie" />&nbsp;{t('dashboard')}
                            </NavLink>
                        ) : null }

                        {roleDistrictWithSector.includes(user.role) ? (
                            <NavLink
                                to={territoryLink}
                                className={cn(
                                    'navbar-item',
                                    { [css(styles.borderIsActive)]: path.startsWith('/district')
                                        || path.startsWith('/territory-sector') },
                                )}>
                                <i className="icon ion-md-map" />&nbsp;{t('regions')}
                            </NavLink>
                        ) : null }

                        {roleBranch.includes(user.role) ? (
                            <NavLink
                                to="/branch/report"
                                className={cn(
                                    'navbar-item',
                                    { [css(styles.borderIsActive)]: path.startsWith('/branch') },
                                )}>
                                <i className="icon ion-md-business" />&nbsp;{t('tib')}
                            </NavLink>
                        ) : null }

                        {user.role === 'main' ? (
                            <Fragment>
                                <NavLink
                                    to="/season"
                                    className={cn(
                                        'navbar-item',
                                        { [css(styles.borderIsActive)]: path.startsWith('/season') },
                                    )}>
                                    <i className="icon ion-md-rainy" />&nbsp;{t('season')}
                                </NavLink>

                                <NavLink
                                    to="/responsible"
                                    className={cn(
                                        'navbar-item',
                                        { [css(styles.borderIsActive)]: path.startsWith('/responsible') },
                                    )}>
                                    <i className="icon ion-md-people" />&nbsp;{t('users')}
                                </NavLink>
                            </Fragment>
                        ) : null }

                        <NavLink
                            to="/docs/start"
                            className={cn(
                                'navbar-item',
                                { [css(styles.borderIsActive)]: path.startsWith('/docs') },
                            )}>
                            <i className="icon ion-md-rainy" />&nbsp;{t('docs')}
                        </NavLink>

                        <NavLink
                            to="/mails"
                            className={cn(
                                'navbar-item',
                                { [css(styles.borderIsActive)]: path.startsWith('/mails') },
                            )}>
                            <i className="icon ion-md-mail" />&nbsp;{t('mails')}
                        </NavLink>
                    </div>

                    <div className="navbar-end">
                        <div className="navbar-item">
                            <div className="buttons">
                                <span className={`button ${theme} is-reversed`} onClick={() => {
                                    // eslint-disable-next-line no-unused-expressions
                                    lang === 'ru' ? setLang('uz') : setLang('ru')
                                }}>
                                    {lang === 'ru' ? <img className={css(styles.image)} src={ruLogo} alt="" />
                                        : <img className={css(styles.image)} src={uzLogo} alt="" />}&nbsp; {lang}
                                </span>

                                <NavLink to="/settings/user" className={`button ${theme} is-reversed`}>
                                    <span title={t('setting')}>
                                        <i className="icon ion-md-person" />&nbsp;
                                        { user.firstName || user.role.email}
                                    </span>
                                </NavLink>

                                <NavLink to="/" onClick={logOut} className={`button ${theme}`}>
                                    <i className="icon ion-md-power" />&nbsp; {t('exit')}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

const styles = StyleSheet.create({
    borderIsActive: {
        backgroundColor: '#fff',
        color: '#3082c5',
    },
    image: {
        width: 20,
        height: 15,
    },
})
