import React, { Fragment } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useHistory } from 'react-router-dom'

export default function BranchReportDistrictItem({ item, branchId }) {
    const history = useHistory()

    return (
        <Fragment>
            <tr className={css(styles.branchReportItem)}>
                <td onClick={() => history.push(`/branch/${branchId}/district/${item.id}/report`)}>
                    {item.name}
                </td>
            </tr>
        </Fragment>
    )
}

const styles = StyleSheet.create({
    branchReportItem: {
        cursor: 'pointer',
        ':hover': {
            color: '#3273dc',
        },
    },

})
