import React, { useState } from 'react'
import queryString from 'query-string'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import Layout from '../components/Layout'
import DistrictTabs from '../components/DistrictTabs'
import { PermissionDenied } from '../components/PermissionDenied'
import { useUser } from '../hooks/user'
import Table from '../components/common/Table'
import { useGetRequest, useLoad } from '../hooks/request'
import { DISTRICT_LIST, DISTRICT_REPORT_LIST } from '../urls'
import DateChoose from '../components/common/DateChoose'
import { DATE_FORMAT } from '../utils/date'
import { useQueryParams } from '../hooks/queryString'
import DistrictArchiveItem from '../components/DistrictArchiveItem'
import Button from '../components/common/Button'
import { useDownloadExcel } from '../hooks/downloadExcel'
import { roleDistrictSector } from '../utils/role'
import useTrans from '../hooks/trans'
import { multiReplace } from '../utils/string'

export default function DistrictArchives() {
    const [user] = useUser()
    const history = useHistory()
    const params = useQueryParams()
    const [page, setPage] = useState(1)
    const date = params.date || moment().format(DATE_FORMAT)
    const districts = useLoad({
        url: DISTRICT_LIST,
        params: { page, sector: user.role === 'districtSector' ? user.districtSector.id : null },
    }, [page])
    const districtDetail = useGetRequest({ url: DISTRICT_REPORT_LIST, params: { date } })
    const downloadExcel = useDownloadExcel(districtDetail.request)
    const t = useTrans()
    const districtResults = districts.response ? districts.response.results : []

    if (!roleDistrictSector.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <DistrictTabs />

            <div className="is-flex is-justify-content-space-between my-5 is-vcentered">
                <h3 className="title is-3 has-text-weight-normal">{t('listDistricts')}</h3>

                <Button
                    className="is-info is-outlined is-pulled-right"
                    icon="ion-md-download"
                    text={t('download')}
                    loading={districtDetail.loading}
                    onClick={() => downloadExcel(multiReplace(t('fullDate'), { '{date}': date }))}
                />
            </div>

            <DateChoose
                date={date}
                onDatesChange={(dates) => {
                    history.push(`?${queryString.stringify({ ...params, ...dates })}`)
                }} />

            <Table
                loading={districts.loading}
                activePage={page}
                onPageChange={setPage}
                items={user.role !== 'main' && user.role !== 'districtSector' ? (
                    districtResults.filter((item) => item.id === user.district.id)
                ) : districtResults}
                columns={{ name: t('name'), limit: t('limit'), water: t('water'), actions: '' }}
                renderItem={(item) => (!item.isArchive ? <DistrictArchiveItem key={item.id} item={item} /> : null)} />
        </Layout>
    )
}
