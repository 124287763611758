import React from 'react'
import { useParams } from 'react-router-dom'
import { css, StyleSheet } from 'aphrodite'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useLoad } from '../hooks/request'
import { DISTRICT_REPORT_TERRITORY, PERIOD, PLAN_DETAIL, TERRITORY_LIST } from '../urls'
import Layout from '../components/Layout'
import DistrictReportForm from '../components/DistrictReportForm'
import Loader from '../components/common/Loader'
import DistrictTabs from '../components/DistrictTabs'
import { PermissionDenied } from '../components/PermissionDenied'
import { useUser } from '../hooks/user'
import { roleDistrict } from '../utils/role'
import { DATE_FORMAT } from '../utils/date'
import { newInitialValues } from '../utils/irrigationReport'
import DistrictReportWaitingMessage from '../components/DistrictReportWaitingMessage'
import AcceptedMessage from '../components/AcceptedMessage'
import RejectedMessage from '../components/RejectedMessage'

export default function DistrictReportItem() {
    const [user] = useUser()
    const { id } = useParams()
    const reportDetail = useLoad({ url: DISTRICT_REPORT_TERRITORY.replace('{districtId}', id) })
    const reportYesterday = useLoad({
        url: DISTRICT_REPORT_TERRITORY.replace('{districtId}', id),
        params: { date: moment().subtract(1, 'days').format(DATE_FORMAT) },
    })

    const territories = useLoad({ url: TERRITORY_LIST, params: { district: id, isArchive: false, size: 100 } })
    const period = useLoad({ url: PERIOD })
    const plans = useLoad({ url: PLAN_DETAIL.replace('{districtId}', id) })

    const reportItemDetail = reportDetail.response ? reportDetail.response.items : []
    const reportYesterdayDetail = reportYesterday.response ? reportYesterday.response.items : []
    const territory = territories.response ? territories.response.results : []

    const initialValues = newInitialValues(
        territories.response, reportDetail.response,
        reportYesterdayDetail, reportItemDetail, period.response,
    )

    if (!roleDistrict.includes(user.role)) {
        return <PermissionDenied />
    }

    if (territories.loading || reportDetail.loading) {
        return (
            <Layout>
                <DistrictTabs className="container mb-5" />
                <div className={css(styles.space)}>
                    <Loader large center />
                </div>
            </Layout>
        )
    }

    if (territories.response && territories.response.count <= 0) {
        return (
            <Layout>
                <DistrictTabs className="container mb-5" />
                <div className="is-size-3 has-text-centered has-text-grey mt-6">
                    Добавьте к этому району сектор территории и территорию и создайте план!
                </div>
            </Layout>
        )
    }

    if (isEmpty(plans.response)) {
        return (
            <Layout>
                <DistrictTabs className="container mb-5" />
                <div className="is-size-3 has-text-centered has-text-grey mt-6">
                    Добавьте план этому району!
                </div>
            </Layout>
        )
    }

    return (
        <Layout>
            <DistrictTabs className="container my-5" />

            {reportDetail.response ? (
                <div>
                    {reportDetail.response.status === 'waiting' ? (
                        <DistrictReportWaitingMessage
                            item={reportDetail.response}
                            onStatusChange={(data) => reportDetail.setResponse({
                                ...reportDetail.response,
                                ...data,
                            })} />
                    ) : null}

                    {reportDetail.response.status === 'accepted' ? (
                        <AcceptedMessage item={reportDetail.response} />
                    ) : null}

                    {reportDetail.response.status === 'rejected' ? (
                        <RejectedMessage item={reportDetail.response} />
                    ) : null}
                </div>
            ) : null}

            <DistrictReportForm
                districtId={id}
                territory={territory}
                report={reportDetail.response ? reportDetail.response : {}}
                disabled={reportDetail.response && reportDetail.response.status === 'accepted'}
                period={period.response ? period.response : {}}
                reportDetail={reportDetail}
                initialValues={initialValues} />
        </Layout>
    )
}

const styles = StyleSheet.create({
    space: {
        marginTop: '5rem',
    },
})
