const uz = {
    irrigationDay: 'Sug\'orish kuni',
    waterConsumptionDay: 'Suv iste\'mol qilish kuni',
    ga: 'ga',
    mln: 'mln m3',
    region: 'Tuman',
    fillIn: 'kechagi ma\'lumotlarni to\'ldirmadi',
    waterRunOffRegion: 'Mintaqalar bo\'yicha suv oqimi',
    waterRunOffDate: 'Sana bo\'yicha suv oqimi',
    allData: 'Barcha tumanlar uchun ma\'lumotlar to\'ldirilgan',
    forToday: 'Bugungi',
    archive: 'Arxiv',
    control: 'Boshqaruv',
    controlTerritory: 'Hududlarni boshqarish',
    controlPlan: 'Rejalarni boshqarish',
    seasons: 'Fasllar',
    controlIrrigation: 'Sug\'orishlarni boshqarish',
    users: 'Foydalanuvchilar',
    listDistricts: 'Tumanlar ro\'yxati',
    listSectorDistricts: 'Tumanlarning sektorlari ro\'yxati',
    listTerritories: 'Hududlar ro\'yxati',
    listSectorTerritories: 'Hududlarning sektorlari ro\'yxati',
    listBranch: 'Sug\'orishni nazorat qilish tizimlari ro\'yxati',
    listChannels: 'Kanallar ro\'yxati',
    territoryReportReplenishment: '"{territory}" Hududi to\'g\'risidagi hisobotni to\'ldirish',
    name: 'Nomlanishi',
    total: 'Jami',
    channel: 'Kanal',
    forMorning: 'Ertalabki',
    forEvening: 'Kechki',
    forAverage: 'O\'rtacha',
    download: 'Yuklab olish',
    add: 'Qo\'shish',
    adding: 'Malumot Qo\'shish',
    cancel: 'Bekor qilish',
    m3: 'm3',
    successSave: 'Muvaffaqiyatli saqlandi',
    noChannels: 'Hech qanday kanal yo\'q. Kanal qo\'shing!!!',
    fillFieldsThenSave: 'Maydonlarni to\'ldiring keyin saqlang',
    save: 'Saqlash',
    noReport: '{date} sanasida {name} uchun hisobot to\'ldirilmagan!',
    limitExpense: 'Cheklov va xarajatlar',
    limitWater: 'Suv chegarasi',
    used: 'Ishlatilgan',
    difference: 'Farq',
    water: 'Suv',
    limit: 'Limit',
    dashboard: 'Dashboard',
    regions: 'Hududlar',
    tib: 'TIB',
    season: 'Mavsum',
    logIn: 'Kirish',
    exit: 'Chiqish',
    editing: 'Tahrirlash',
    enterName: 'Nomlanishini kiriting',
    period: 'Davr',
    createdAt: 'Ma\'lumot yaratilgan vaqt',
    vegetation: 'Vegetatsion',
    noVegetation: 'No Vegetatsion',
    change: 'O\'zgartirish',
    irrigationType: 'Sug\'orish turi',
    irrigationCount: 'Sug\'orish miqdori',
    status: 'Holati',
    filledIn: 'To\'ldirilgan',
    m3sek: 'm3/sek',
    grain: 'G\'alla sug\'orish',
    cotton: 'G\'o\'za sug\'orish',
    cottonWash: 'Pushta sug\'orish',
    repeatIrrigation: 'Takroriy sug\'orish',
    garden: 'Tomorqa va boshqalarni sug\'orish',
    preparation: 'Sho\'r yuvishga tayyorlash',
    saltWash: 'Sho\'r yuvish',
    saltWashPlow: 'Shudgor yerlarini sho\'rini yuvish',
    grainPreparation: 'G\'alla sug\'orish qishgi',
    potatoPreparation: 'Kartoshka yerlarini shurini yuvish',
    deleteSector: 'Haqiqatan ham ushbu sektorni arxivlashni xohlaysizmi?',
    addSeason: 'Mavsumda sug\'orishni qo\'shing !!!',
    addTerritoryToRegion: 'Ushbu hududga hudud qo\'shing.',
    fullDate: '{date} raqamida mintaqa bo\'yicha hisobot tugallanmagan!',
    channels: 'Kanallar',
    required: 'Bu talab qilinadigan maydon',
    changeRegion: 'Hududni o\'zgartirish:',
    branches: 'Filiallar',
    back: 'Orqaga',
    addNewRegion: 'Yangi hudud qo\'shing:',
    fullName: 'Ism',
    surname: 'Familiya',
    role: 'Rol',
    roleProperties: 'Rol xususiyatlari',
    deleteUser: 'Haqiqatan ham foydalanuvchini oʻchirib tashlamoqchimisiz?',
    changeSector: 'Sektorni o\'zgartirish',
    empty: 'Bo\'sh',
    archived: 'Arxiv',
    archiveRegionSector: 'Haqiqatan ham ushbu sektor va uning hududlarini arxivlashni xohlaysizmi?',
    addSector: 'Yangi sektor qo\'shing:',
    position: 'Lavozim',
    informationChanged: 'Ma\'lumotlar o\'zgartirildi',
    passwordChanged: 'Kiritilgan parollar mos emas',
    oldPassword: 'Iltimos, eski parolingizni to\'g\'ri kiriting.',
    newPassword: 'Parol o\'zgartirildi',
    addOldPassword: 'Eski parol',
    placeholderOldPassword: 'Eski parolingizni kiriting',
    addNewPassword: 'Yangi parol',
    placeholderNewPassword: 'Yangi parolni kiriting',
    addNewPasswordElse: 'Yana yangi parol',
    placeholderNewPasswordElse: 'Yangi parolni yana kiriting',
    account: 'Foydalanuvchi hisobi',
    selectStatus: 'Holatlardan kamida bittasini tanlang',
    success: 'Muvaffaqiyatli o\'zgartirildi',
    setting: 'Profil sozlamalari',
    welcome: 'Tizimga xush kelibsiz',
    logInSystem: 'Kirish',
    auth: 'Kirish',
    docs: 'Ma\'lumotnoma',
    categories: 'Kategoriyalar',
    categoriesList: 'Kategoriyalar ro\'yxati',
    topics: 'Mavzular',
    topicList: 'Mazular ro\'yxati',
    title: 'Sarlavha',
    shortTitle: 'Qizqacha sarlavha',
    slug: 'Slug',
    body: 'Ma\'lumot',
    category: 'Kategoriya',
    changeDocs: 'Ma\'lumonomani o\'zgartirish',
    territoryName: 'Hududlar nomi',
    plan: 'Режа',
    irrigation: 'sug\'orish',
    waterLimit: 'Sug\'orish uchun berilgan suv limiti',
    amountWaterPreviousDay: 'O\'tgan kun suv miqdori',
    amountWater: 'Berilgan suv miqdori',
    irrigationCapacity: 'Sug\'orish imkoniyati',
    totalIrrigation: 'Jami sug\'orilgan maydon',
    forOneDay: '1 kunda',
    watermanCount: 'Suvchilar soni',
    themes: 'Temalar',
    primaryTheme: 'Firuza rang',
    linkTheme: 'Baland ko\'k rang',
    infoTheme: 'Havo rang',
    successTheme: 'Yashil rang',
    warningTheme: 'Sariq rang',
    dangerTheme: 'Qizil rang',
    blackTheme: 'Qora rang',
    darkTheme: 'Ochiq qora rang',
    lightTheme: 'Kul rang',
    whiteTheme: 'Oq rang',
    print: 'Chop etish',
    enterTitle: 'Sarlavhani kirgizing',
    enterShortTitle: 'Qizqa sarlavhani kirgizing',
    enterSlug: 'Slugni kirgizing',
    to: 'Kimga',
    isDoneTitle: 'Qilindi',
    fileTitle: 'Biriktirilgan fayl',
    listMails: 'Xatlar ro\'yxati',
    sendMail: 'Xatni yuborish',
    isDone: 'Qilindi',
    mailDelete: 'Siz rostdanxam yo\'q qilmoqchimisiz?',
    send: 'Yuborish',
    isNotDone: 'Qilinmagan',
    mails: 'Xatlar',
    listReports: 'Malumotlar ro\'yxati',
    date: 'Sana',
    statusWaiting: 'Hisobot tekshiruvchi ko\'rishini kutobti. Yuboruvchi {responsible}. Iltimos kuting',
    statusAccepted: 'Hisobot qabul qilindi. Yuboruvchi {responsible}. Qabul qiluvchi {acceptedBy}',
    statusRejected: 'Hisobot qabul qilinmadi. Yuboruvchi {responsible}. Tekshiruvchi {rejectedBy}. Sabab {reason}',
    newReportMessage: '{name} yangi hisobot.',
    newReportLink: 'Hisobotga o\'tish.',
    accept: 'Qabul qilish',
    reject: 'Bekor qilish',
    reason: 'Sabab',
    enterReason: 'Sababni kiriting',
    waiting: 'Kutilmoqda',
}

const ru = {
    irrigationDay: 'Орошения день',
    waterConsumptionDay: 'Расхода воды день',
    ga: 'га',
    mln: 'млн м3',
    region: 'Район',
    fillIn: 'не заполнили данные за вчера',
    waterRunOffRegion: 'Сток воды по регионам ',
    waterRunOffDate: 'Сток воды по дате',
    allData: 'Все районы заполнили данные за',
    forToday: 'За сегодняшний день',
    archive: 'Архив',
    control: 'Управление',
    controlTerritory: 'Управления территориями',
    controlPlan: 'Управление планами',
    seasons: 'Сезоны',
    controlIrrigation: 'Управление орошением',
    users: 'Пользователи',
    listDistricts: 'Список районов',
    listSectorDistricts: 'Список секторов района',
    listTerritories: 'Список Территории',
    listSectorTerritories: 'Список секторов Территории',
    listBranch: 'Список ирригационных систем управления',
    listChannels: 'Список каналов',
    territoryReportReplenishment: 'Заполнение отчёта территории "{territory}"',
    name: 'Название',
    total: 'Итог',
    channel: 'Канал',
    forMorning: 'Утренний',
    forEvening: 'Вечерний',
    forAverage: 'Средний',
    download: 'Скачать',
    add: 'Добавить',
    adding: 'Добавление информации',
    cancel: 'Отмена',
    m3: 'м3',
    successSave: 'Успешно сохранён',
    noChannels: 'Нет каналов. Добавьте канал !!!',
    fillFieldsThenSave: 'Заполните поля потом сохраните',
    save: 'Сохранить',
    noReport: 'В {date} отчет по {name} не заполнен!',
    limitExpense: 'Лимит и расход',
    limitWater: 'Лимит воды',
    used: 'Использовано',
    difference: 'Разница',
    water: 'Вода',
    limit: 'Лимит',
    dashboard: 'Дашборд',
    regions: 'Районы',
    tib: 'ТИБ',
    season: 'Сезон',
    logIn: 'Авторизоваться',
    exit: 'Выйти',
    editing: 'Редактирование',
    enterName: 'Введите название',
    period: 'Период',
    createdAt: 'Дата создания',
    vegetation: 'Вигитатцион',
    noVegetation: 'Не Вигитатцион',
    change: 'Изменить',
    irrigationType: 'Вид орошения',
    irrigationCount: 'Количество орошения',
    status: 'Статус',
    filledIn: 'Заполнено',
    m3sek: 'м3/сек',
    grain: 'Орошение зерна',
    cotton: 'Орошение хлопка',
    cottonWash: 'Порошковое орошение',
    repeatIrrigation: 'Повторный полив',
    garden: 'Орошение земли и др.',
    preparation: 'Подготовка к промыванию',
    saltWash: 'Промывка',
    saltWashPlow: 'Промывка пашни',
    grainPreparation: 'Орошение зерна зимное',
    potatoPreparation: 'Промышка земли картофелья',
    deleteSector: 'Вы действительно хотите архивировать этот сектор?',
    addSeason: 'Добавьте орошения в сезон !!!',
    addTerritoryToRegion: 'Добавьте территория к этому району.',
    fullDate: 'В {date} числе отчет по районом не заполнено!',
    channels: 'Каналы',
    required: 'Это обязательное поле',
    changeRegion: 'Изменить район:',
    branches: 'Филиалы',
    back: 'Назад',
    addNewRegion: 'Добавить новый район:',
    fullName: 'Имя',
    surname: 'Фамилия',
    role: 'Роль',
    roleProperties: 'Свойство ролей',
    deleteUser: 'Вы действительно хотите удалить пользователя?',
    changeSector: 'Изменить сектор:',
    empty: 'Пусто',
    archived: 'Архивировать',
    archiveRegionSector: 'Вы действительно хотите архивировать этот сектор и его районы?',
    addSector: 'Добавить новый сектор:',
    position: 'Должность',
    informationChanged: 'Информация изменен',
    passwordChanged: 'Введенные пароли не совпадают',
    oldPassword: 'Введете старый пароль правильно.',
    newPassword: 'Пароль изменен',
    addOldPassword: 'Старый пароль',
    placeholderOldPassword: 'Введите старый пароль',
    addNewPassword: 'Новый пароль',
    placeholderNewPassword: 'Введите новый пароль',
    addNewPasswordElse: 'Новый пароль ещё раз',
    placeholderNewPasswordElse: 'Введите новый пароль ещё раз',
    account: 'Аккаунт пользователя',
    selectStatus: 'Выберите хотя бы один из статусов',
    success: 'Успешно изменено',
    setting: 'Настройки профиля',
    welcome: 'Добро пожаловать в систему',
    logInSystem: 'Войдите в систему',
    auth: 'Войти',
    docs: 'Документация',
    categories: 'Категории',
    categoriesList: 'Список категории',
    topics: 'Темы',
    topicList: 'Список Тем',
    title: 'Название',
    shortTitle: 'Короткое название',
    slug: 'Slug',
    body: 'Контент',
    category: 'Категория',
    changeDocs: 'Изменить документацию',
    territoryName: 'Название территории',
    plan: 'План',
    irrigation: 'орошение',
    waterLimit: 'Лимит воды для орошения',
    amountWaterPreviousDay: 'Количество воды в предыдущий день',
    amountWater: 'Количество воды, подаваемое',
    irrigationCapacity: 'Оросительная способность',
    totalIrrigation: 'Общая орошаемая площадь',
    forOneDay: '1 день',
    watermanCount: 'Количество пловцов',
    themes: 'Темы',
    primaryTheme: 'Бирюзовый',
    linkTheme: 'Тёмно-синий',
    infoTheme: 'Голубой',
    successTheme: 'Зелёный',
    warningTheme: 'Жёлтый',
    dangerTheme: 'Красный',
    blackTheme: 'Черный',
    darkTheme: 'Светло-черный',
    lightTheme: 'Серый',
    whiteTheme: 'Белый',
    print: 'Печать',
    enterTitle: 'Введите название',
    enterShortTitle: 'Введите короткое название',
    enterSlug: 'Введите slug',
    to: 'Кому',
    isDoneTitle: 'Сделано',
    fileTitle: 'Прикрипленный файл',
    listMails: 'Список письм',
    sendMail: 'Отправить письмо',
    isDone: 'Сделано',
    mailDelete: 'Вы действительно хотите удалить письмо?',
    send: 'Отправить',
    isNotDone: 'Не сделано',
    mails: 'Письма',
    listReports: 'Список сводок',
    date: 'Дата',
    statusWaiting: 'Отчёт в режиме ожидания. Отправил {responsible}. Пожалуйста подождите',
    statusAccepted: 'Отчёт принять. Отправил {responsible}. Принял {acceptedBy}',
    statusRejected: 'Отчёт отказано. Отправил {responsible}. Отказаль {rejectedBy}. Причина {reason}',
    newReportMessage: 'Новый отчёт от {name}.',
    newReportLink: 'Перейти к отчёту.',
    accept: 'Принять',
    reject: 'Отказать',
    reason: 'Причина',
    enterReason: 'Введите причину',
    waiting: 'В ожидании',
}

export const languages = { uz, ru }
