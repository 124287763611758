import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useLoad } from '../hooks/request'
import { BRANCH_DETAIL, BRANCH_REPORT_DETAIL, DISTRICT_LIST } from '../urls'
import Layout from '../components/Layout'
import BranchTabs from '../components/BranchTabs'
import Table from '../components/common/Table'
import BranchReportDistrictItem from '../components/BranchReportDistrictItem'
import Button from '../components/common/Button'
import { useUser } from '../hooks/user'
import { PermissionDenied } from '../components/PermissionDenied'
import Loader from '../components/common/Loader'
import { domain } from '../utils/request'
import { roleBranch } from '../utils/role'
import { useMessage } from '../hooks/message'
import useTrans from '../hooks/trans'

export default function BranchReportDistrict() {
    const [user] = useUser()
    const { branchId } = useParams()
    const [page, setPage] = useState(1)
    const branchReportDetail = useLoad({ url: BRANCH_REPORT_DETAIL.replace('{branchId}', branchId) })
    const districtList = useLoad({ url: DISTRICT_LIST, params: { branch: branchId, page } }, [page])
    const branch = useLoad({ url: BRANCH_DETAIL.replace('{id}', branchId) })
    const [showMessage] = useMessage()
    const t = useTrans()

    async function download() {
        if (!branchReportDetail.response || !branchReportDetail.response.xlsx) {
            showMessage('Отчет не заполнено!', 'is-danger')
            return
        }

        const a = document.createElement('a')
        a.href = [`${domain}/${branchReportDetail.response.xlsx}`]
        a.setAttribute('download', 'response')
        a.click()
    }

    if (!roleBranch.includes(user.role)) {
        return <PermissionDenied />
    }

    if (branch.loading || districtList.loading || branchReportDetail.loading) {
        return <Layout><BranchTabs /><Loader large center /></Layout>
    }

    return (
        <Layout>
            <BranchTabs />

            <h5 className="title is-3 has-text-weight-normal pt-3">
                {t('listDistricts')} "{branch.response ? branch.response.name : null}"
            </h5>

            <Table
                loading={districtList.loading}
                activePage={page}
                onPageChange={setPage}
                totalCount={districtList.response ? districtList.response.count : 0}
                items={districtList.response ? districtList.response.results : []}
                columns={{ name: t('name') }}
                renderItem={(items) => (
                    <BranchReportDistrictItem key={items.id} item={items} branchId={branchId} />
                )} />

            { districtList.response && districtList.response.count > 0 ? (
                <div className="has-text-right">
                    <Button
                        className="is-info mt-5"
                        icon="ion-md-download"
                        text={t('download')}
                        loading={branchReportDetail.loading}
                        onClick={download} />
                </div>
            ) : null}
        </Layout>
    )
}
