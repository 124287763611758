import React, { useState, useEffect } from 'react'
import useTrans from '../hooks/trans'

export default function DistrictReplenishedItem({ item }) {
    const [differenceIcon, setDifferenceIcon] = useState({
        class: 'warning',
        icon: 'ios-close-circle',
    })
    const t = useTrans()

    useEffect(() => {
        if (item.amountWater - item.limitWater >= 0) {
            setDifferenceIcon({ class: 'success', icon: 'md-checkmark' })
        } else {
            setDifferenceIcon({ class: 'danger', icon: 'ios-trending-up' })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <tr>
            <td>
                <b>{item.name}</b>
                <p className="is-size-7">{item.sector.name}</p>
            </td>

            <td>
                <div className="columns is-vcentered is-variable is-1">
                    <div className="column is-narrow">
                        <button className="button is-link is-reversed is-light is-rounded">
                            <i className="icon ion-ios-speedometer" />
                        </button>
                    </div>
                    <div className="column">
                        { item.limitWater ? item.limitWater : 0 } {t('mln')}
                    </div>
                </div>
            </td>

            <td>
                <div className="columns is-vcentered is-variable is-1">
                    <div className="column is-narrow">
                        <button className="button is-info is-light is-rounded">
                            <i className="icon ion-ios-water" />
                        </button>
                    </div>
                    <div className="column">
                        { item.amountWater ? item.amountWater : 0 } {t('mln')}
                    </div>
                </div>
            </td>

            <td>
                <div className="columns is-vcentered is-variable is-1">
                    <div className="column">
                        <button className={`button is-${differenceIcon.class} is-light is-rounded`}>
                            {item.amountWater - item.limitWater} {t('mln')} &nbsp;
                            <i className={`icon ion-${differenceIcon.icon}`} />
                        </button>
                    </div>
                </div>
            </td>
        </tr>
    )
}
