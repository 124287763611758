import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import Input from './common/Input'
import Button from './common/Button'
import { required } from '../utils/validators'
import MultiSelect from './common/MultiSelect'
import Select from './common/Select'
import { useLoad } from '../hooks/request'
import { CHANNEL_LIST } from '../urls'
import useTrans from '../hooks/trans'

export default function DistrictForm({
    onCancel,
    branch,
    onSubmit,
    loading,
    initialValues = {},
    sector,
}) {
    const [branchId, setBranchId] = useState(initialValues.branch || '')
    const t = useTrans()
    const channels = useLoad({ url: CHANNEL_LIST, params: { branch: branchId } }, [branchId])
    const channelResults = channels.response ? (
        channels.response.results.map((item) => ({ value: item.id, label: item.name }))
    ) : []

    return (
        <Formik onSubmit={onSubmit} initialValues={{
            name: '',
            sector,
            ...initialValues,
        }}>
            {({ setFieldValue }) => (
                <Form>
                    <Input
                        label={t('name')}
                        name="name"
                        validate={required} />

                    <Select
                        empty
                        name="branch"
                        label={t('branches')}
                        onChange={(e) => {
                            setFieldValue('branch', e.target.value)
                            setBranchId(e.target.value)
                            setFieldValue('channels', '')
                        }}
                        validate={required}
                        options={branch}
                    />

                    {branchId ? (
                        <MultiSelect
                            empty
                            name="channels"
                            label={t('channel')}
                            validate={required}
                            options={channelResults} />
                    ) : null}

                    <div className="is-pulled-right">
                        <Button
                            text={t('back')}
                            className="is-danger ml-2"
                            onClick={onCancel} />&nbsp; &nbsp;

                        <Button
                            loading={loading}
                            text={t('save')}
                            className="is-success"
                            type="submit" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
