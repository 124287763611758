import React from 'react'
import Input from './common/Input'
import Checkbox from './common/Checkbox'
import useTrans from '../hooks/trans'

export default function PeriodItem({ item }) {
    const t = useTrans()

    return (
        <tr>
            <td>
                {t(item.name)}
            </td>

            <td>
                <Input name={item.key} type="number" required />
            </td>

            <td>
                <Checkbox name={item.active} />
            </td>
        </tr>
    )
}
