import React from 'react'
import { useStream } from '../hooks/request'
import { BRANCH_REPORT_STATUS } from '../urls'
import useTrans from '../hooks/trans'

export default function BranchReportWaitingMessage({ onStatusChange, item }) {
    const t = useTrans()
    useStream(BRANCH_REPORT_STATUS.replace('{id}', item.id), (data, connection) => {
        if (data.status !== 'waiting') {
            connection.close()
            onStatusChange(data)
        }
    })
    const responsible = item.responsible ? `${item.responsible.firstName} ${item.responsible.lastName}` : ''

    return (
        <div className="container my-5 notification is-warning">
            {t('statusWaiting').replace('{responsible}', responsible)}
        </div>
    )
}
