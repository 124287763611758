import React, { Fragment } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useHistory } from 'react-router-dom'
import { useModal } from '../hooks/modal'
import BranchUpdate from './BranchUpdate'

export default function BranchItem({ item, onUpdate }) {
    const history = useHistory()

    const [showUpdateModal, hideUpdateModal] = useModal(
        <BranchUpdate item={item} onUpdate={() => {
            onUpdate()
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    return (
        <Fragment>
            <tr className={css(styles.branchItem)}>
                <td onClick={() => history.push(`/branch/settings/${item.id}`)}>
                    {item.name}
                </td>

                <td className="is-flex is-justify-content-flex-end">
                    <i onClick={showUpdateModal} className="icon pointer ion-md-create" />
                </td>
            </tr>
        </Fragment>
    )
}

const styles = StyleSheet.create({
    branchItem: {
        cursor: 'pointer',
        ':hover': {
            color: '#3273dc',
        },
    },

})
