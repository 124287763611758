import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { isEmpty } from 'lodash'
import { useFormikContext } from 'formik'
import { required } from '../utils/validators'
import TableInput from './TableInput'

export default function BranchReportFormItem({ item, index }) {
    const [average, setAverage] = useState(0)
    const { errors, values } = useFormikContext()

    useEffect(() => {
        let { morning, dinner } = values[index] || 0

        morning = morning !== undefined ? morning : dinner
        dinner = dinner !== undefined ? dinner : morning

        setAverage((morning + dinner) / 2)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values])

    return (
        <tr key={item.id} className={css(styles.border)}>
            <td className={css(styles.border)}>{item.name}</td>

            <td className={css(styles.border)}>
                <TableInput
                    className={cn(css(styles.input), 'has-text-centered')}
                    type="number"
                    name={`${index}.morning`}
                    isValid={!isEmpty(errors) && errors[index] ? (
                        errors[index].morning
                    ) : null}
                    validate={required}
                    min={0}
                    step="0.01" />
            </td>

            <td className={css(styles.border)}>
                <TableInput
                    className={cn(css(styles.input), 'has-text-centered')}
                    validate={required}
                    isValid={!isEmpty(errors) && errors[index] ? (
                        errors[index].dinner
                    ) : null}
                    name={`${index}.dinner`}
                    type="number"
                    min={0}
                    step="0.01"
                />
            </td>

            <td className={css(styles.border)}>
                <TableInput
                    className={cn(css(styles.input), 'has-text-centered')}
                    disabled
                    value={average}
                    type="number"
                    min={0}
                    step="0.01"
                />
            </td>
        </tr>
    )
}

const styles = StyleSheet.create({
    border: {
        border: '1px solid black',
        width: 1130,
    },
    table: {
        border: '1px solid black',
        width: '100%',
    },
    container: {
        overflow: 'auto',
        padding: '0 100px 15px',
    },
    element: {
        minWidth: 100,
        scrollSnapStop: 'normal',
    },
    input: {
        all: 'unset',
        padding: '7px 0',
        width: '100%',
    },
    button: {
        marginRight: 10,
    },
})
