import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import { role } from '../utils/role'
import Button from './common/Button'
import { email, required, validator } from '../utils/validators'
import Input from './common/Input'
import Select from './common/Select'
import { useGetRequest } from '../hooks/request'
import { BRANCH_LIST, DISTRICT_LIST, DISTRICT_SECTOR_LIST } from '../urls'
import useTrans from '../hooks/trans'

const urlComponents = {
    district: DISTRICT_LIST,
    branch: BRANCH_LIST,
    districtSector: DISTRICT_SECTOR_LIST,
}

export default function ResponsibleForm({ onSubmit, onCancel, loading, initialValues = {} }) {
    const [position, setPosition] = useState([])
    const options = Object.entries(role).map(([value, label]) => ({ value, label }))
    const t = useTrans()
    const roles = useGetRequest({})

    async function onChangePosition(positionName) {
        if (positionName !== 'main') {
            const { response, success } = await roles.request({ url: urlComponents[positionName] })

            if (success) {
                setPosition({ name: positionName, results: response.results })
            }

            return
        }

        setPosition([])
    }

    return (
        <Formik onSubmit={onSubmit} initialValues={{
            firstName: '',
            role: '',
            lastName: '',
            email: '',
            ...initialValues,
        }}>
            {({ setFieldValue }) => (
                <Form>
                    <Input name="firstName" label={t('fullName')} validate={required} />

                    <Input name="lastName" label={t('surname')} validate={required} />

                    <Input name="email" label="Email" validate={validator(required, email)} />

                    <Select
                        name="role"
                        label={t('position')}
                        optionLabel="label"
                        optionValue="value"
                        onChange={(e) => {
                            setFieldValue('role', e.target.value)
                            onChangePosition(e.target.value)
                        }}
                        disabled={loading}
                        validate={required}
                        options={options} />

                    {!isEmpty(position.results) ? (
                        <Select
                            empty
                            name={position.name}
                            label={role[position.name]}
                            options={position.results}
                            disabled={loading}
                            validate={required} />
                    ) : null}

                    <div className="is-pulled-right">
                        <Button
                            onClick={onCancel}
                            icon="ion-md-close"
                            text={t('cancel')}
                            disabled={loading}
                            className="is-danger" />&nbsp;&nbsp;

                        <Button
                            loading={loading}
                            text={t('save')}
                            type="submit"
                            icon="ion-md-checkmark"
                            disabled={loading}
                            className="is-success" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
