import React from 'react'
import { Form, Formik } from 'formik'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { isEmpty } from 'lodash'
import Button from './common/Button'
import { getIrrigationType } from '../utils/irrigationTypes'
import Input from './common/Input'
import useTrans from '../hooks/trans'

export default function DistrictPlanForm({ initialValues, loading, onSubmit, territory, period }) {
    const seasonPeriod = getIrrigationType(period ? period.season.type : '')
    const activePeriod = seasonPeriod.filter((item) => period[item.active])
    const t = useTrans()

    if (isEmpty(activePeriod)) {
        return (<div className="is-size-3 has-text-centered has-text-grey mt-6">{t('addSeason')}</div>)
    }

    if (territory.response && territory.response.count <= 0) {
        return (
            <div className="is-size-3 has-text-centered has-text-grey mt-6">
                {t('addTerritoryToRegion')}
            </div>
        )
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            <Form>
                <table className={cn(css(styles.border), css(styles.element), 'has-text-centered')}>
                    <thead>
                        <tr className={css(styles.border)}>
                            <th className={css(styles.border)}>{t('territoryName')}</th>
                            {activePeriod.map((item) => (
                                <th key={item.key} className={css(styles.border)}>{t(item.name)} {t('plan')}</th>
                            ))}
                        </tr>

                        {territory.response ? territory.response.results.map((item) => (
                            <tr key={item.id} className={css(styles.border)}>
                                <td className={css(styles.border)}>{item.name}</td>

                                {activePeriod.map((season) => (
                                    <td key={season.key} className={css(styles.border)}>
                                        <Input
                                            name={`${item.id}.${season.key}`}
                                            type="number"
                                            className={cn(css(styles.input), 'has-text-centered')} />
                                    </td>
                                ))}
                            </tr>
                        )) : null}
                    </thead>
                </table><br />

                {!isEmpty(territory.response.results) ? (
                    <Button
                        loading={loading}
                        type="submit"
                        text={t('save')}
                        className="is-link is-pulled-right" />
                ) : null}
            </Form>
        </Formik>
    )
}

const styles = StyleSheet.create({
    border: {
        border: '1px solid black',
        minWidth: 110,
        padding: '5px 8px',
    },
    container: {
        overflow: 'auto',
        padding: '0 100px',
        minHeight: '77vh',
    },
    element: {
        width: '100%',
        scrollSnapStop: 'normal',
    },
    input: {
        all: 'unset',
    },
})
