import React from 'react'
import { useHistory } from 'react-router-dom'
// eslint-disable-next-line import/no-cycle
import { useStream } from '../hooks/request'
import { DISTRICT_REPORT_STATUS_LIST } from '../urls'
import useTrans from '../hooks/trans'
import { randomString } from '../utils/string'

export default function ParseMessage({ messages, setMessages, reports, setReports }) {
    const t = useTrans()
    const history = useHistory()

    useStream(DISTRICT_REPORT_STATUS_LIST, (data) => {
        setReports([...reports, ...(data || [])])
        const newMessages = []

        data.forEach((i) => {
            let link = `/district/${i.id}/reports`

            if (i.type === 'branch') {
                link = `/branch/${i.id}/district/${i.districtId}/report`
            }

            const content = (
                <span>
                    {t('newReportMessage').replace('{name}', i.name)}&nbsp;

                    <span onClick={() => history.push(link)} className="pointer has-text-link">
                        {t('newReportLink')}
                    </span>
                </span>
            )
            newMessages.push({ id: randomString(), content, className: 'is-success' })
        })

        setMessages([...newMessages, ...messages])
    })

    return ''
}
