import { range } from 'lodash'

function irrigationPeriod(period, activeName, irrigationName) {
    if (period[activeName]) {
        return range(period[irrigationName]).map(() => ({ day: 0 }))
    }
    return undefined
}

export function newInitialValues(
    territories, reportDetail, reportYesterdayDetail, reportItemDetail, period,
) {
    const initialValues = { items: [] }

    if (territories && territories.results) {
        // eslint-disable-next-line array-callback-return
        territories.results.map((item) => {
            if (reportDetail) {
                const yesterdayWater = reportYesterdayDetail.filter((i) => (i.territory.id === item.id))[0]

                initialValues.items.push({
                    yesterdayWater: yesterdayWater ? yesterdayWater.amountWater : 0,
                    ...reportItemDetail.filter((i) => (i.territory.id === item.id))[0],
                })
            } else {
                const yesterdayWater = reportYesterdayDetail.filter((i) => (i.territory.id === item.id))[0]

                if (period) {
                    const grain = irrigationPeriod(period, 'grainActive', 'grain')
                    const cotton = irrigationPeriod(period, 'cottonActive', 'cotton')
                    const repeatIrrigation = irrigationPeriod(period, 'repeatIrrigationActive', 'repeatIrrigation')
                    const garden = irrigationPeriod(period, 'gardenActive', 'garden')
                    const saltWash = irrigationPeriod(period, 'saltWashActive', 'saltWash')
                    const saltWashPlow = irrigationPeriod(period, 'saltWashPlowActive', 'saltWashPlow')
                    const cottonWash = irrigationPeriod(period, 'cottonWashActive', 'cottonWash')
                    const preparation = irrigationPeriod(period, 'preparationActive', 'preparation')
                    const grainPreparation = irrigationPeriod(period, 'grainPreparationActive', 'grainPreparation')
                    const potatoPreparation = irrigationPeriod(period, 'potatoPreparationActive', 'potatoPreparation')

                    initialValues.items.push({
                        limit: 0,
                        amountWater: 0,
                        capacity: 0,
                        totalAreaDay: 0,
                        watermanCount: 0,
                        grain,
                        cotton,
                        repeatIrrigation,
                        garden,
                        saltWash,
                        saltWashPlow,
                        cottonWash,
                        preparation,
                        grainPreparation,
                        potatoPreparation,
                        yesterdayWater: yesterdayWater ? yesterdayWater.amountWater : 0,
                    })
                    // eslint-disable-next-line array-callback-return
                    return
                }
                initialValues.items.push({
                    yesterdayWater: yesterdayWater ? yesterdayWater.amountWater : 0,
                })
            }
        })
    }
    return initialValues
}
