import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import Layout from '../components/Layout'
import { useLoad, usePutRequest } from '../hooks/request'
import Table from '../components/common/Table'
import { PERIOD, SEASON_ACTIVE } from '../urls'
import SeasonTabs from '../components/SeasonTabs'
import Loader from '../components/common/Loader'
import PeriodItem from '../components/PeriodItem'
import Button from '../components/common/Button'
import { getIrrigationType } from '../utils/irrigationTypes'
import { useMessage } from '../hooks/message'
import useTrans from '../hooks/trans'
import { roleMain } from '../utils/role'
import { PermissionDenied } from '../components/PermissionDenied'
import { useUser } from '../hooks/user'

export default function Period() {
    const [user] = useUser()
    const [page, setPage] = useState(1)
    const season = useLoad({ url: SEASON_ACTIVE, params: { page } }, [page])
    const period = useLoad({ url: PERIOD })
    const periodCreate = usePutRequest({ url: PERIOD })
    const [showMessage] = useMessage()
    const t = useTrans()

    // eslint-disable-next-line consistent-return
    async function onSubmit(data) {
        if (!Object.values(data).includes(true)) {
            return showMessage(t('selectStatus'), 'is-danger')
        }

        const { error } = await periodCreate.request({ data })

        if (error) {
            showMessage(error.data, 'is-danger')
            // eslint-disable-next-line consistent-return
            return
        }
        showMessage(t('success'), 'is-success')
    }

    const initialValues = {
        grain: 0,
        grainActive: false,
        cotton: 0,
        cottonActive: false,
        repeatIrrigation: 0,
        repeatIrrigationActive: false,
        garden: 0,
        gardenActive: false,
        saltWash: 0,
        saltWashActive: false,
        saltWashPlow: 0,
        saltWashPlowActive: false,
        cottonWash: 0,
        cottonWashActive: false,
        preparation: 0,
        preparationActive: false,
        grainPreparation: 0,
        grainPreparationActive: false,
        potatoPreparation: 0,
        potatoPreparationActive: false,
        ...period.response,
    }

    if (!season.response || season.loading || period.loading) {
        return <Layout><SeasonTabs /><Loader large center /></Layout>
    }

    if (!roleMain.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <SeasonTabs />

            <div className="is-flex is-justify-content-space-between is-align-items-center my-5">
                <h1 className="title has-text-weight-normal"><span>{t('controlIrrigation')}</span></h1>
            </div>

            <Formik onSubmit={onSubmit} initialValues={initialValues}>
                <Form>
                    <Table
                        activePage={page}
                        onPageChange={setPage}
                        items={getIrrigationType(season.response ? season.response.type : '')}
                        columns={{
                            name: t('irrigationType'),
                            count: t('irrigationCount'),
                            status: t('status'),
                        }}
                        renderItem={(item) => <PeriodItem key={item.key} item={item} />} />

                    <Button
                        type="submit"
                        text={t('save')}
                        className="button is-pulled-right mt-2 is-link"
                        loading={periodCreate.loading} />
                </Form>
            </Formik>
        </Layout>
    )
}
