import React from 'react'
import { usePostRequest } from '../hooks/request'
import { CHANNEL_LIST } from '../urls'
import ChannelForm from './ChannelForm'
import ServerError from './common/ServerError'
import useTrans from '../hooks/trans'

export default function ChannelCreate({ onCancel, onSuccess, branch }) {
    const channelCreate = usePostRequest({ url: CHANNEL_LIST })
    const t = useTrans()

    async function onSubmit(data, actions) {
        const { success } = await channelCreate.request({ data: { ...data, branch } })
        if (success) {
            actions.resetForm()
            onSuccess()
        }
    }

    return (
        <div>
            <b>{t('adding')}:</b><br /><br />
            <ServerError error={channelCreate.error} />
            <ChannelForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={channelCreate.loading} />
        </div>
    )
}
