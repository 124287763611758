import React from 'react'
import { useDeleteRequest } from '../hooks/request'
import { USERS_DETAIL } from '../urls'
import { useMessage } from '../hooks/message'
import { role } from '../utils/role'
import useTrans from '../hooks/trans'
import { useModal } from '../hooks/modal'
import ResponsibleUpdate from './ResponsibleUpdate'

export default function ResponsibleItem({ item, onSuccess }) {
    const userDelete = useDeleteRequest({ url: USERS_DETAIL.replace('{id}', item.id) })
    const t = useTrans()
    const [showMessage] = useMessage()

    const [showUpdateModal, hideUpdateModal] = useModal(
        <ResponsibleUpdate
            user={item}
            onSuccess={() => {
                onSuccess()
                hideUpdateModal()
            }}
            onCancel={() => {
                hideUpdateModal()
            }} />,
    )

    async function onDelete() {
        if (!global.confirm(t('deleteUser'))) return

        const { error } = await userDelete.request()

        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }

        onSuccess()
    }

    return (
        <tr>
            <td>
                {item.lastName}
            </td>

            <td>
                {item.firstName}
            </td>

            <td>
                {item.email}
            </td>

            <td>
                {role[item.role]}
            </td>

            <td>
                {item.role && item.role !== 'main' ? item[item.role].name : ''}
            </td>

            <td className="has-text-right">
                <i className="icon pointer ion-md-create" title={t('change')} onClick={showUpdateModal} />

                <i onClick={onDelete} className="icon pointer ion-md-trash" />
            </td>
        </tr>
    )
}
