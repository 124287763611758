import React, { useState } from 'react'
import Layout from '../components/Layout'
import { USER_LIST } from '../urls'
import { useLoad } from '../hooks/request'
import Table from '../components/common/Table'
import ResponsibleItem from '../components/ResponsibleItem'
import Button from '../components/common/Button'
import { useModal } from '../hooks/modal'
import ResponsibleCreate from '../components/ResponsibleCreate'
import { useUser } from '../hooks/user'
import { PermissionDenied } from '../components/PermissionDenied'
import useTrans from '../hooks/trans'
import { roleMain } from '../utils/role'

export default function Responsible() {
    const [user] = useUser()
    const [page, setPage] = useState(1)
    const t = useTrans()
    const users = useLoad({ url: USER_LIST, params: { page } }, [page])
    const usersResults = users.response ? users.response.results : []
    const filteredUsers = usersResults.filter((item) => !item.email.includes(user.email))

    const [showCreateModal, hideCreateModal] = useModal(
        <ResponsibleCreate
            users={users.response ? users.response.results : []}
            onSuccess={() => {
                users.request()
                hideCreateModal()
            }} onCancel={() => {
                hideCreateModal()
            }} />,
    )

    if (!roleMain.includes(user.role)) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <div className="is-flex is-justify-content-space-between is-align-items-center mt-5 mb-0">
                <h1 className="title is-3 has-text-weight-normal"><span>{t('users')}</span></h1>

                <Button
                    onClick={showCreateModal}
                    text={t('add')}
                    icon="ion-md-add"
                    className="is-pulled-right is-link is-outlined mb-2" />
            </div>

            <Table
                loading={users.loading}
                activePage={page}
                pageSize={15}
                totalCount={users.response ? users.response.count : 0}
                onPageChange={setPage}
                items={filteredUsers}
                columns={{
                    name: t('fullName'),
                    surname: t('surname'),
                    email: 'Email',
                    role: t('role'),
                    roles: t('roleProperties'),
                    actions: '',
                }}
                renderItem={(item) => (
                    <ResponsibleItem
                        responsible={users.response ? users.response.results : []}
                        onSuccess={users.request}
                        key={item.id}
                        item={item} />
                )} />
        </Layout>
    )
}
