import React, { Fragment } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useHistory } from 'react-router-dom'
import useTrans from '../hooks/trans'

export default function BranchReportItem({ item }) {
    const history = useHistory()
    const t = useTrans()

    return (
        <Fragment>
            <tr>
                <td
                    className={css(styles.branchReportItem)}
                    onClick={() => history.push(`/branch/${item.id}/district`)}>
                    {item.name}
                </td>

                <td>
                    {item.total} {t('m3')}
                </td>
            </tr>
        </Fragment>
    )
}

const styles = StyleSheet.create({
    branchReportItem: {
        cursor: 'pointer',
        ':hover': {
            color: '#3273dc',
        },
    },

})
