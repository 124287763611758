import React from 'react'
import { useParams } from 'react-router-dom'
import { useLoad, usePutRequest } from '../hooks/request'
import { CHANNEL_LIST, BRANCH_REPORT_DISTRICT, DISTRICT_DETAIL } from '../urls'
import Layout from '../components/Layout'
import BranchTabs from '../components/BranchTabs'
import BranchReportForm from '../components/BranchReportForm'
import { useMessage } from '../hooks/message'
import Loader from '../components/common/Loader'
import { PermissionDenied } from '../components/PermissionDenied'
import { useUser } from '../hooks/user'
import { roleBranch } from '../utils/role'
import useTrans from '../hooks/trans'
import AcceptedMessage from '../components/AcceptedMessage'
import RejectedMessage from '../components/RejectedMessage'
import BranchReportWaitingMessage from '../components/BranchReportWaitingMessage'

export default function BranchReportCreate() {
    const [user] = useUser()
    const { branchId, districtId } = useParams()
    const channels = useLoad({ url: CHANNEL_LIST, params: { district: districtId } })
    const [showMessage] = useMessage()
    const branchReportDetail = useLoad({ url: BRANCH_REPORT_DISTRICT.replace('{districtId}', districtId) })
    const branchReportUpdate = usePutRequest({ url: BRANCH_REPORT_DISTRICT.replace('{districtId}', districtId) })
    const district = useLoad({ url: DISTRICT_DETAIL.replace('{id}', districtId) })
    const initialValues = {}
    const t = useTrans()

    if (!roleBranch.includes(user.role)) {
        return <PermissionDenied />
    }

    if (channels.response) {
        channels.response.results.forEach((item, index) => {
            initialValues[index] = {
                morning: 0,
                dinner: 0,
            }
        })
    }

    if (branchReportDetail.response) {
        // eslint-disable-next-line array-callback-return
        branchReportDetail.response.items.forEach((item, index) => {
            initialValues[index] = {
                morning: item.morning,
                dinner: item.dinner,
            }
        })
    }

    async function onSubmit(data) {
        const items = channels.response ? channels.response.results.map((item, index) => ({
            channel: item.id,
            morning: data[index].morning || 0,
            dinner: data[index].dinner || 0,
        })) : []

        const { response, error } = await branchReportUpdate.request(
            { data: { items, branch: branchId, district: districtId } },
        )

        if (error) {
            showMessage(error.data, 'is-danger')
            return
        }
        branchReportDetail.setResponse(response)
        showMessage(t('successSave'), 'is-success')
    }

    if (channels.loading || branchReportDetail.loading) {
        return (
            <Layout>
                <BranchTabs />
                <Loader large center />
            </Layout>
        )
    }

    return (
        <Layout>
            <BranchTabs />
            <h1 className="title is-3 has-text-weight-normal my-5">
                {t('territoryReportReplenishment').replace(
                    '{territory}',
                    district.response ? district.response.name : null,
                )}
            </h1>

            {branchReportDetail.response ? (
                <div>
                    {branchReportDetail.response.status === 'waiting' ? (
                        <BranchReportWaitingMessage
                            item={branchReportDetail.response}
                            onStatusChange={(data) => branchReportDetail.setResponse({
                                ...branchReportDetail.response,
                                ...data,
                            })} />
                    ) : null}

                    {branchReportDetail.response.status === 'accepted' ? (
                        <AcceptedMessage item={branchReportDetail.response} />
                    ) : null}

                    {branchReportDetail.response.status === 'rejected' ? (
                        <RejectedMessage item={branchReportDetail.response} />
                    ) : null}
                </div>
            ) : null}

            {channels.response && channels.response.count > 0 ? (
                <div className="mt-5">
                    <BranchReportForm
                        report={branchReportDetail.response ? branchReportDetail.response : {}}
                        branchReportDetail={branchReportDetail}
                        onSubmit={onSubmit}
                        channels={channels}
                        loading={branchReportUpdate.loading}
                        initialValues={initialValues} />
                </div>
            ) : (
                <div className="box mt-5">
                    <p>
                        {t('noChannels')}
                    </p>
                </div>
            )}
        </Layout>
    )
}
