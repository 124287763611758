export const SIGNIN = '/users/sign-in'
export const USERS_LIST = '/users/users'
export const USERS_DETAIL = '/users/user/{id}'
export const SIGNUP = '/users/sign-up'
export const SIGNOUT = '/users/sign-out'
export const USER_SETTINGS = '/users/user_settings'
export const USER_LIST = '/users/user'
export const USER_INVITATION = '/users/invitation'
export const USER_SIGN_UP = '/users/invitation-sign-up/{code}'
export const CHANGE_PASSWORD = '/users/change_password'
export const CONFIRM = '/users/confirm'
export const RESET_PASSWORD = '/users/reset_password'
export const RESET_LINK = '/users/reset_link'
export const MAIL_LIST = '/users/mails'
export const MAIL_DETAIL = '/users/mail/{id}'

export const BRANCH_LIST = '/structure/branch/'
export const BRANCH_DETAIL = '/structure/branch/{id}'

export const DISTRICT_LIST = '/structure/district'
export const DISTRICT_DETAIL = '/structure/district/{id}'
export const EMPTY_DISTRICT_REPORT = '/structure/empty-district-report'
export const DISTRICT_SECTOR_LIST = '/structure/district-sector'
export const DISTRICT_SECTOR_DETAIL = '/structure/district-sector/{id}'

export const CHANNEL_LIST = '/structure/channel/'
export const CHANNEL_DETAIL = '/structure/channel/{id}'

export const TERRITORY_LIST = '/structure/territory/'
export const TERRITORY_DETAIL = '/structure/territory/{id}'
export const TERRITORY_ALL_LIST = '/structure/territory-all'

export const TERRITORY_SECTOR_LIST = '/structure/territory-sector/'
export const TERRITORY_SECTOR_DETAIL = '/structure/territory-sector/{id}'

export const BRANCH_REPORT_DETAIL = 'reports/branch-report-detail/{branchId}'
export const BRANCH_REPORT_DISTRICT = 'reports/branch-report-district/{districtId}'
export const BRANCH_REPORT = 'reports/branch-report'
export const BRANCH_REPORT_STATUS = '/reports/branch-report-status/{id}'
export const BRANCH_REPORT_CHANGE_DETAIL = 'reports/branch-report-change-detail/{id}'

export const DISTRICT_REPORT_LIST = '/reports/district-report'
export const DISTRICT_DETAIL_REPORT_LIST = '/reports/district-reports/{districtId}'
export const DISTRICT_REPORT_TERRITORY = '/reports/district-report-territory/{districtId}'
export const DISTRICT_REPORT_STATUS = '/reports/district-report-status/{id}'
export const DISTRICT_REPORT_STATUS_LIST = '/reports/district-report-status-list'
export const DISTRICT_REPORT_DETAIL = '/reports/district-report/{id}'

export const PLAN_LIST = '/reports/plan-list'
export const PLAN_ITEM_LIST = '/reports/plan-item-list'
export const PLAN_ITEM_DETAIL = '/reports/plan-item/{id}'
export const PLAN_DETAIL = '/reports/plan/{districtId}'

export const SEASON_LIST = '/reports/season-list'
export const SEASON_ACTIVE = '/reports/season-active'
export const PERIOD = '/reports/period'

export const STATISTICS_WATER_LIMIT = '/stats/water-limit'
export const STATISTICS_TOTAL_AREA = '/stats/total-area'
export const STATISTICS_AMOUNT_WATER = '/stats/amount-water'
export const STATISTICS_DISTRICT_REPLENISH = '/stats/district-replenish'
export const STATISTICS_DAY_AMOUNT_WATER = '/stats/day-amount-water'

export const CATEGORY_LIST = '/docs/category'
export const CATEGORY_DETAIL = '/docs/category/{id}'
export const TOPIC_LIST = '/docs/topic'
export const TOPIC_DETAIL = '/docs/topic/{slug}'
