import React from 'react'
import useTrans from '../hooks/trans'
import { multiReplace } from '../utils/string'

export default function RejectedMessage({ item }) {
    const t = useTrans()
    const responsible = item.responsible ? `${item.responsible.firstName} ${item.responsible.lastName}` : ''
    const rejectedBy = item.rejectedBy ? `${item.rejectedBy.firstName} ${item.rejectedBy.lastName}` : ''

    return (
        <div className="container my-5 notification is-danger">
            {multiReplace(t('statusRejected'), {
                '{rejectedBy}': rejectedBy,
                '{responsible}': responsible,
                '{reason}': item.reason,
            })}
        </div>
    )
}
