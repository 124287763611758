import React, { Fragment } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Form, Formik } from 'formik'
import { isEmpty, range } from 'lodash'
import { DISTRICT_REPORT_DETAIL, DISTRICT_REPORT_TERRITORY } from '../urls'
import { usePutRequest } from '../hooks/request'
import Button from './common/Button'
import { useMessage } from '../hooks/message'
import { domain } from '../utils/request'
import { getActiveIrrigation } from '../utils/irrigationTypes'
import DistrictReportFormItem from './DistrictReportFormItem'
import useTrans from '../hooks/trans'
import { roleMain } from '../utils/role'
import { useUser } from '../hooks/user'
import { useModal } from '../hooks/modal'
import DistrictRejectModal from './DistrictRejectModal'

export default function DistrictReportForm({
    period,
    territory,
    districtId,
    report,
    reportDetail,
    disabled,
    initialValues = {},
}) {
    const districtCreate = usePutRequest({ url: DISTRICT_REPORT_TERRITORY.replace('{districtId}', districtId) })
    const [showMessage] = useMessage()
    const t = useTrans()
    const [user] = useUser()
    const activeIrrigation = getActiveIrrigation(period)
    const reportUpdate = usePutRequest({ url: DISTRICT_REPORT_DETAIL.replace('{id}', report.id) })

    const [showRejectModal, hideRejectModal] = useModal(
        <DistrictRejectModal
            item={report}
            onSuccess={() => {
                reportDetail.request()
                hideRejectModal()
            }}
            onCancel={() => {
                hideRejectModal()
            }} />,
    )

    async function onSubmit(data) {
        const value = territory.map((item, index) => ({
            ...data.items[index],
            grain: !isEmpty(data.items[index].grain) ? data.items[index].grain : undefined,
            cotton: !isEmpty(data.items[index].cotton) ? data.items[index].cotton : undefined,
            repeatIrrigation: !isEmpty(data.items[index].repeatIrrigation) ? (
                data.items[index].repeatIrrigation
            ) : undefined,
            garden: !isEmpty(data.items[index].garden) ? data.items[index].garden : undefined,
            grainPreparation: !isEmpty(data.items[index].grainPreparation) ? (
                data.items[index].grainPreparation
            ) : undefined,
            saltWash: !isEmpty(data.items[index].saltWash) ? data.items[index].saltWash : undefined,
            saltWashPlow: !isEmpty(data.items[index].saltWashPlow) ? data.items[index].saltWashPlow : undefined,
            cottonWash: !isEmpty(data.items[index].cottonWash) ? data.items[index].cottonWash : undefined,
            preparation: !isEmpty(data.items[index].preparation) ? data.items[index].preparation : undefined,
            potatoPreparation: !isEmpty(data.items[index].potatoPreparation) ? (
                data.items[index].potatoPreparation
            ) : undefined,
            territory: item.id,
        }))

        const { error } = await districtCreate.request({
            data: { ...data, district: districtId, items: value },
        })

        if (error) {
            showMessage(error.data, 'is-danger')
            return
        }

        showMessage('Успешно сохранена!', 'is-success')
        reportDetail.request()
    }

    async function downloadExcel() {
        if (!reportDetail.response || !reportDetail.response.xlsx) {
            showMessage('Заполните поля и сохраните', 'is-danger')
            return
        }

        const a = document.createElement('a')
        a.href = [`${domain}/${reportDetail.response.xlsx}`]
        a.setAttribute('download', 'response')
        a.click()
    }

    async function onAccepted() {
        const { error } = await reportUpdate.request({ data: {
            ...report,
            status: 'accepted',
            responsible: report.responsible ? report.responsible.id : null,
            acceptedBy: report.acceptedBy ? report.acceptedBy.id : null,
            rejectedBy: report.rejectedBy ? report.rejectedBy.id : null,
        } })

        if (error) {
            showMessage(error.data, 'is-danger')
            return
        }

        reportDetail.request()
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
            <Form>
                <div className={css(styles.container)}>
                    <table className={cn(
                        css(styles.border),
                        css(styles.element),
                        'has-text-centered',
                    )}>
                        <thead>
                            <tr>
                                <th className={cn(css(styles.border), 'px-2 py-1')}>{t('territoryName')}</th>

                                <th className={cn(css(styles.border), 'px-2 py-1')}>
                                    {t('waterLimit')} {t('m3sek')}
                                </th>

                                <th className={cn(css(styles.border), 'px-2 py-1')}>
                                    {t('amountWaterPreviousDay')} {t('m3sek')}
                                </th>

                                <th className={cn(css(styles.border), 'px-2 py-1')}>
                                    {t('amountWater')} {t('m3sek')}
                                </th>

                                <th className={cn(css(styles.border), 'px-2 py-1')}>
                                    {t('irrigationCapacity')} {t('ga')}
                                </th>

                                <th className={cn(css(styles.border), 'px-2 py-1')}>
                                    {t('totalIrrigation')} {t('ga')} <br /> {t('forOneDay')}
                                </th>

                                {activeIrrigation.map((item) => (
                                    range(1, period[item.key] + 1).map((i) => (
                                        <th key={i} className={cn(css(styles.border), 'px-2 py-1')}>
                                            {t(item.name)} {i}-{t('irrigation')}
                                        </th>
                                    ))
                                ))}

                                <th className={cn(css(styles.border), 'px-2 py-1')}>{t('watermanCount')}</th>
                            </tr>
                        </thead>

                        <tbody className={css(styles.container)}>
                            {territory.map((item, index) => (
                                <DistrictReportFormItem
                                    item={item}
                                    index={index}
                                    disabled={disabled}
                                    activeIrrigation={activeIrrigation}
                                    period={period} />
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="mt-4 is-flex is-justify-content-flex-end">
                    {reportDetail.response && roleMain.includes(user.role) ? (
                        <Fragment>
                            <Button
                                text={t('accept')}
                                onClick={onAccepted}
                                loading={reportDetail.loading || reportUpdate.loading}
                                className="is-success"
                                icon="ion-md-checkmark" />

                            <Button
                                onClick={showRejectModal}
                                text={t('reject')}
                                className="is-danger ml-2"
                                icon="ion-md-close" />
                        </Fragment>
                    ) : null}

                    <Button
                        className="button is-link ml-2"
                        icon="ion-md-download"
                        text={t('download')}
                        loading={reportDetail.loading}
                        onClick={downloadExcel} />

                    <Button
                        type="submit"
                        text={t('save')}
                        disabled={disabled}
                        loading={districtCreate.loading || reportDetail.loading || reportUpdate.loading}
                        className="is-success ml-2"
                        icon="ion-md-checkmark" />
                </div>
            </Form>
        </Formik>
    )
}

const styles = StyleSheet.create({
    border: {
        border: '1px solid black',
        minWidth: 110,
    },
    container: {
        overflow: 'auto',
        padding: '0 3% 10px',
    },
    element: {
        minWidth: 100,
        scrollSnapStop: 'normal',
    },
})
